<template>
  <div>
    <el-card class="box-card" style="width: 400px;border-radius: 10px;">
      <div id="del-div">
        <el-link type="primary" icon="el-icon-close" @click="close()"></el-link>
      </div>
        <img :src="infoWindow.imgUrl1"  v-if="infoWindow.imgUrl1" class="image">
        <img :src="infoWindow.imgUrl2"  v-if="infoWindow.imgUrl2" class="image">
        <div style="padding: 14px;">
          <span>{{infoWindow.addr}}</span>
        </div>
    </el-card>
  </div>
</template>
<script>
export default {
  props: {
    infoWindow: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    // 关闭
    close() {
      // 高德地图信息窗关闭的api
      this.infoWindow.close()
    },
    edit() {
      console.log('编辑按钮测试')
    },
    del() {
      console.log('删除按钮测试')
    }
  }
}
</script>

<style lang="css" scoped>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>

