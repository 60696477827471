import request from '@/utils/http.js'

/**
 * 数据分析
 * @param {object} data 
 * @returns 
 */
export const queryPlanBillAnalysis = data => {
  return request({
    url: '/biz/plan/oms/queryPlanBillAnalysis',
    method: 'post',
    data
  })
}
/**
 * 订单管理
 * @param {object} data 
 * @returns 
 */
export const queryPlanBillList = data => {
  return request({
    url: '/biz/plan/oms/queryPlanBillList',
    method: 'post',
    data
  })
}
/**
 * 获取订单详情
 * @param {object} data 
 * @returns 
 */
export const getPlanBill = data => {
  return request({
    url: '/biz/plan/oms/getPlanBill',
    method: 'get',
    params: data
  })
}
/**
 * 下单
 * @param {object} data 
 * @returns 
 */
export const addPlanBill = data => {
  return request({
    url: '/biz/plan/oms/addPlanBill',
    method: 'post',
    data
  })
}
/**
 * 订单修改
 * @param {object} data 
 * @returns 
 */
export const updatePlanBill = data => {
  return request({
    url: '/biz/plan/oms/updatePlanBill',
    method: 'post',
    data
  })
}
/**
 * 取消订单
 * @param {object} data 
 * @returns 
 */
export const cancelPlanBill = data => {
  return request({
    url: '/biz/plan/oms/cancelPlanBill',
    method: 'get',
    params: data
  })
}
/**
 * 发货联系人电话
 * @param {object} data 
 * @returns 
 */
export const getConsignerContacts = data => {
  return request({
    url: '/base/cnor/queryConsignerContacts',
    method: 'post',
    data
  })
}
/**
 * 发货地址
 * @param {object} data 
 * @returns 
 */
export const getConsignerAddrs = data => {
  return request({
    url: '/base/cnor/queryConsignerAddrs',
    method: 'post',
    data
  })
}
/**
 * 收货单位列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverList',
    method: 'get',
    params: data
  })
}
/**
 * 收货人列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverContactList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverContactList',
    method: 'get',
    params: data
  })
}
/**
 * 收货地址列表
 * @param {object} data 
 * @returns 
 */
export const getBaseReceiverAddrList = data => {
  return request({
    url: '/base/rcvr/omsQueryBaseReceiverAddrList',
    method: 'get',
    params: data
  })
}

// 工单列表查询
export function queryList(params) {
  return request({
    url: '/worker/order/findByPage',
    method: 'post',
    data: params
  })
}
// 工单恢复列表查询
export function orderRecoverList(params) {
  return request({
    url: '/worker/order/V2/findByPage',
    method: 'post',
    data: params
  })
}

// 批量修改和备注
export function batchEdit(params) {
  return request({
    url: '/worker/order/batchEdit',
    method: 'post',
    data: params
  })
}

// 工单详情  根据工单id查询
export function orderDetail(params) {
  return request({
    url: '/worker/order/details',
    method: 'get',
    params
  })
}

// 工单详情 根据工单号查询
export function orderDetailByOrderNo(params) {
  return request({
    url: '/worker/order/detailsByOrderNo',
    method: 'get',
    params
  })
}


// 渣土场查询
export function ztcQuery(params) {
  return request({
    url: '/ztc/query',
    method: 'get',
    params
  })
}


// 车队查询
export function getFleetList(params) {
  return request({
    url: '/fleet/query',
    method: 'get',
    params
  })
}

// 土类型查询
export function getTlxList(params) {
  return request({
    url: '/tlx/query',
    method: 'get',
    params
  })
}

// 导出
export function dataExport(params) {
  return request({
    url: '/worker/order/export',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}

// 恢复一个工单
export function resumeSingleOrder(params) {
  return request({
    url: '/worker/order/resume',
    method: 'get',
    params
  })
}

// 批量工地工单结算
export function orderProjectSettle(params) {
  return request({
    url: '/settle/order/settle',
    method: 'post',
    data: params
  })
}

// 批量渣土场工单结算
export function orderMuckSettle(params) {
  return request({
    url: '/settle/order/muck/settle',
    method: 'post',
    data: params
  })
}

// 结算结果
export function orderSettleResult(params) {
  return request({
    url: '/settle/order/v2/findByPage',
    method: 'post',
    data: params
  })
}

// 根据手机号获取工地老板
export function getWorkBossByPhone(params) {
  return request({
    url: '/tenant/listByPhone',
    method: 'get',
    params
  })
}


// 结算凭证导出
export function settleDetailExport(params) {
  return request({
    url: '/settle/order/details/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}


// 导出结算列表
export function settleOrderExport(params) {
  return request({
    url: '/settle/order/export',
    method: 'get',
    params,
    responseType: 'blob'
  })

}

export function getOrderTrack(params) {
  return request({
    url: '/worker/order/getOrderTrack',
    method: 'get',
    params
  })
}

export function syncOrder(params) {
  return request({
    url: '/worker/order/syncOrder',
    method: 'post',
    params
  })
}
