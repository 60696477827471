<template>
    <div class="map-container">
      <el-amap :mapStyle="mapStyle" :plugin="plugin" :center="center" :amap-manager="amapManager" :zoom="zoom" :events="events" :resize-enable="true" class="map">
      </el-amap>
      <div ref="dialog" style="position: fixed;">

        <div class="dialog">
          <div class="dialog-title"><span>起点</span>
          </div>
          <div class="lc-flex-row">
            <viewer v-if="startImg1">
              <img :src="startImg1" class="img" />
            </viewer>
            <viewer v-if="startImg2">
              <img :src="startImg2" class="img" />
            </viewer>

          </div>
          <div class="dialog-item"><span>位置:{{startAdr}}</span></div>
        </div>

    </div>
    <!-- <div class="toolbar">
      <van-button @click="checkLoad()">轨迹回放</van-button>
    </div> -->
    </div>
</template>

<script>
import { AMapManager, lazyAMapApiLoaderInstance } from 'vue-amap'
import { getOrderTrack } from "../../api/order";
import InfoWindow from "@/components/InfoWindow";
const amapManager = new AMapManager()
export default {
  name: 'MapTrack',
  components: {
    InfoWindow
  },
  data() {
    return {
      plugin: [
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', //  地图工具条
        'AMap.MapType' //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      ],
      // ------------高德地图参数开始-----------------
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 13, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      center: [116.478935, 39.997761],
      amapManager,
      events: {
        init(map) {
          console.log('map init ok', map)
        }
      },
      orderId: this.$route.query.id,

      startAdr:'',
      endAdr:'',
      startInfoWindow:null,
      endInfoWindow:null,
      map:null,
      showStartInfoWindow:false,
      showEndInfoWindow:false,
      StartInfoWindow:{},
      EndInfoWindow:{},

      startImg1:'',
      startImg2:''


    }
  },
  mounted() {
    this.checkLoad()
  },
  methods: {
    checkLoad() {
      lazyAMapApiLoaderInstance.load().then(() => {
        // 查询数据库获取轨迹，如果获取不到，则换成模拟的。
        let params={
          id: this.orderId
        }
        getOrderTrack(params).then(res => {
          console.log(res)
          if (res.code === 200) {
             this.startImg1 = res.data.startCarHeadUrl
             this.startImg2 = res.data.startCarBodyUrl
            this.startTrack(res.data.gpsList)
          } else {
          //   this.$message.error();(res.msg)
            this.startTrack(null)
          }
        })

      })
    },
    startTrack(lineArr) {
      let map = amapManager.getMap()
      this.map = map
      // 轨迹点
      let position = [116.478935, 39.997761]
      if(lineArr==null){
        lineArr = [
          [116.478935, 39.997761],
          [116.478939, 39.997825],
          [116.478912, 39.998549],
          [116.478912, 39.998549],
          [116.478998, 39.998555],
          [116.478998, 39.998555],
          [116.479282, 39.99856],
          [116.479658, 39.998528],
          [116.480151, 39.998453],
          [116.480784, 39.998302],
          [116.480784, 39.998302],
          [116.481149, 39.998184],
          [116.481573, 39.997997],
          [116.481863, 39.997846],
          [116.482072, 39.997718],
          [116.482362, 39.997718],
          [116.483633, 39.998935],
          [116.48367, 39.998968],
          [116.484648, 39.999861]
        ]
      }else{
        position = lineArr[0];
      }

      // console.log("轨迹数据：", position)
      // console.log("轨迹数据22：", lineArr)

      let _this = this

      // 创建主体
      let marker = new AMap.Marker({
        map: map,
        position: position,
        icon: 'https://webapi.amap.com/images/car.png',
        offset: new AMap.Pixel(-26, -13),
        autoRotation: true,
        angle: -90
      })



      // 创建一个 Icon
      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(25, 34),
        // 图标的取图地址
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        // 图标所用图片大小
        imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3)
      });

      /*var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(30, 30),
        // 图标的取图地址
        image: '@/assets/img/start.png',
        // 图标所用图片大小
        imageSize: new AMap.Size(30, 30),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(-9, -3)
      });*/


      // 将 icon 传入 marker
      var startMarker = new AMap.Marker({
        position: new AMap.LngLat(lineArr[0][0], lineArr[0][1]),
        icon: startIcon,
        offset: new AMap.Pixel(-13, -30)
      });

      // 创建一个 icon
     /* var endIcon = new AMap.Icon({
        size: new AMap.Size(30,30),
        image:  '@/assets/img/end.png',
        imageSize: new AMap.Size(30, 30),
        imageOffset: new AMap.Pixel(-95, -3)
      });
*/
      // 创建一个 icon
      var endIcon = new AMap.Icon({
        size: new AMap.Size(25, 34),
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
        imageSize: new AMap.Size(135, 40),
        imageOffset: new AMap.Pixel(-95, -3)
      });


      // 将 icon 传入 marker
      var endMarker = new AMap.Marker({
        position: new AMap.LngLat(lineArr[lineArr.length-1][0], lineArr[lineArr.length-1][1]),
        icon: endIcon,
        offset: new AMap.Pixel(-13, -30)
      });

      var geocoder;
      AMap.plugin("AMap.Geocoder",function(){
        geocoder  = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode

        })
        geocoder.getAddress(lineArr[0], function(status, result) {
          console.log("start--status==",status)
          console.log("start--result==",result)
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            _this.startAdr =   result.regeocode.formattedAddress;

            startMarker.content = _this.$refs.dialog

            //信息窗口实例
            const infoWindow = new AMap.InfoWindow({
              anchor: "bottom-left",
              //
              offset: new AMap.Pixel(-200, -200),
              //隐藏原生弹窗边框和关闭按钮
              isCustom: true,
              autoMove: true,
              avoid: [20, 20, 20, 20],
              // 点击地图关闭
              closeWhenClickMap: true,
            });
            _this.startInfoWindow =infoWindow
            // allInfoWindow.value = infoWindow

            const markerClick = (e) => {
              console.log(e, 'e');
              // 标注的点击事件
              infoWindow.setContent(e.target.content);
              infoWindow.open(map, e.target.getPosition());
              _this.map.setFitView()
            }
            startMarker.on('click', markerClick);
            // startMarker.content = '<div><i>当前位置：'+_this.startAdr+'</i></div>';
            // startMarker.on('click',markerStartClick );
          }
        })

        geocoder.getAddress(lineArr[lineArr.length-1], function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            console.log("end--status==",status)
            console.log("end--result==",result)
            _this.endAdr =   result.regeocode.formattedAddress;
            _this.endInfoWindow  = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
            endMarker.content = '<div><i>当前位置：'+_this.endAdr+'</i></div>';
            endMarker.on('click', markerEndClick);
          }
        })
      })

      function markerEndClick(e) {
        _this.endInfoWindow.setContent(e.target.content);
        _this.endInfoWindow.open(_this.map, e.target.getPosition());
      }

      //构建自定义信息窗体
     function createInfoWindow(title, content) {
        var info = document.createElement("div");
        info.className = "custom-info input-card content-window-card";

        //可以通过下面的方式修改自定义窗体的宽高
        //info.style.width = "400px";
        // 定义顶部标题
        var top = document.createElement("div");
        var titleD = document.createElement("div");
        var closeX = document.createElement("img");
        top.className = "info-top";
        titleD.innerHTML = title;
        closeX.src = "https://webapi.amap.com/images/close2.gif";
        closeX.onclick = closeInfoWindow;

        top.appendChild(titleD);
        top.appendChild(closeX);
        info.appendChild(top);

        // 定义中部内容
        var middle = document.createElement("div");
        middle.className = "info-middle";
        middle.style.backgroundColor = 'white';
        middle.innerHTML = content;
        info.appendChild(middle);

        // 定义底部内容
        var bottom = document.createElement("div");
        bottom.className = "info-bottom";
        bottom.style.position = 'relative';
        bottom.style.top = '0px';
        bottom.style.margin = '0 auto';
        var sharp = document.createElement("img");
        sharp.src = "https://webapi.amap.com/images/sharp.png";
        bottom.appendChild(sharp);
        info.appendChild(bottom);
        return info;
      }
      //关闭信息窗体
      function closeInfoWindow() {
        _this.map.clearInfoWindow();
      }

      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: map,
        path: lineArr,
        showDir: true,
        strokeColor: '#28F', //线颜色
        strokeWeight: 6, //线宽
        strokeOpacity: 1, //线透明度
        strokeStyle: 'solid' //线样式
      })

      // 回执经过的轨迹
      let passedPolyline = new AMap.Polyline({
        map: map,
        strokeColor: '#AF5', //线颜色
        strokeWeight: 6 //线宽
      })

      //经过轨迹的更新
      marker.on('moving', function (e) {
        passedPolyline.setPath(e.passedPath)
      })

      map.add([startMarker, endMarker]);

      // 自动适配视图
      map.setFitView()

      // 开始沿坐标移动
      marker.moveAlong(lineArr, 200)
    },


  }
}
</script>

<style scoped>
/*@import '../../styles/amap.css';*/
.map-container  {
  width: 100%;
  height: 100%;
}
.dialog {
  width: 200px;
  /*height: 100px;*/
  /*background-image: url('../assets/images/弹窗1.png');*/
  /*background-size: 100% 100%;*/
  background-color: #2d2b2f;
  background-repeat: no-repeat;
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.dialog-title {
  font-size: 12px !important;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.dialog-title {
  font-size: 12px !important;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img {
  width: 80px !important;
  height: 100px !important;
}


.dialog-item {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  font-size: 12px !important;
  text-align: left;
}

.detail {
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #2FD7D7;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}

</style>
